/***************************************************************
* The idea is to have some global styles applicable to all components
* and to have local styles specific to each component.
* each component & page should have its own css file.
* App.css and index.css should be considered 'global' styles.
* index.css is broader than App.css, App.css should specify 
* initial styling for all components. index.css generally
* specifies the styling for HTML elements.
****************************************************************/

/*
color palette:
main orange: #F27931
second orange: #FC7556 (gradient)
pale orange: #FFEFE4
main teal: #2DA5B9
main teal-light: #D8EEF2
second teal: #1B6481
secondary teal-light: #DDE8EC
error red: #FF5638
*/

@font-face {
    font-family: 'Work Sans';
    src: local('Work Sans'), url('../public/Fonts/WorkSans-VariableFont_wght.ttf');
}

@font-face {
    font-family: 'Quicksand';
    src: local('Quicksand'), url('../public/Fonts/Quicksand-VariableFont_wght.ttf') format("truetype");
}

@font-face {
    font-family: 'Quicksand Regular';
    src: local('Quicksand Regular'), url('../public/Fonts/Quicksand-Regular.ttf') format("truetype");
}

@font-face {
    font-family: 'Quicksand Light';
    src: local('Quicksand Light'), url('../public/Fonts/Quicksand-Light.ttf') format("truetype");
}

body {
    margin: 0;
    font-family: 'Quicksand', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
    position: relative;
    min-height: 100%;
}

body {
    background-color: #F4FAFE !important;
}

h1 {
    font-family: 'Quicksand', 'Roman', 'Helvetica', 'sans-serif';
    font-size: 40px !important;
    font-weight: 700;
    color: #0279CF;
    margin-top: 15px !important;
}

h1.white {
    color: #ffffff;
}

h2 {
    font-family: 'Quicksand', 'Helvetica', 'sans-serif';
    font-size: 30px !important;
    font-weight: 700;
    color: #0279CF;
}

h3 {
    font-family: 'Quicksand', 'Helvetica', 'sans-serif';
    font-size: 26px !important;
    font-weight: 700;
    color: #0279CF;
}

h4 {
    font-family: 'Quicksand', 'Helvetica', 'sans-serif';
    font-size: 22px !important;
    font-weight: 700;
    color: #000000;
}

h5 {
    font-family: 'Quicksand', 'Helvetica', 'sans-serif';
    font-size: 22px !important;
    font-weight: 700;
    color: #0279CF;
}

h6 {
    font-family: 'Quicksand', 'Helvetica', 'sans-serif';
    font-size: 20px !important;
    font-weight: 700;
    color: #000000;
}

label {
    font-family: 'Work Sans';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    color: #23415F;
}

.extra-label-orange {
    text-align: center;
    margin: 20px 0;
    color: #c93e00;
    font-style: italic;
    font-size: 19px;
    font-weight: 500;
}

.sublabel {
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    color: #23415F;
    align-self: center;
}

.style-fa {
    margin-left: 20px;
    margin-right: 20px;
    font-size: 2em;
    color: #22a5ba
}

.attention {
    color: red;
    font-weight: bold;
    font-size: 24px;
}

.error-text {
    color: #FF5638
}

.success-text {
    font-weight: bold;
    color: #05a200
}

#footer {
    position: absolute;
    left: 0;
    bottom: -150px;
    right: 0;
    color: white;
    height: 40px;
    margin-top: 20px;
    background-color: #0279CF;
}

.blue-text {
    color: #1B6481 !important;
    font-weight: bold;
}

.padding25px {
    padding: 25px;
}

.teal-gradient {
    background-image: linear-gradient(180deg, #0279CF 0%, #25A9EF 100%);
}

.bad-error {
    width: 600px;
}

